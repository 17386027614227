@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;                 
}

code {
  font-family: Arial, Helvetica, sans-serif;
}



